import React from "react";

import Helmet from "../../components/Helmet";
import DeliveryInfoPageContent from "../../shopcrate-framework/page-content/DeliveryInfoPageContent";

function DeliveryInfo() {
    return (
        <React.Fragment>
            <Helmet title="Gegevens"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Gegevens</h1>
                </div>
            </div>
            <div className="container">
                <DeliveryInfoPageContent/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(DeliveryInfo);
