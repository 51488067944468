import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import CartContext from "../context/internal/CartManager";
import Loading from "../components/Loading";
import CartCard from "../components/CartCard";
import CartPriceSummary from "../components/CartPriceSummary";

function CartPageContent() {
    const cartContext = useContext(CartContext);

    return (
        <React.Fragment>
            { cartContext.error !== null ? (
                <Alert variant="danger">{ cartContext.error }</Alert>
            ) : cartContext.cart === null || cartContext.products === null ? (
                <Loading/>
            ) : cartContext.cart.length === 0 ? (
                <div className="text-center">
                    <i className="fas fa-shopping-cart mb-2" style={{ fontSize: "3rem" }}/>
                    <h1>
                        <Trans i18nKey="cartEmptyTitle"/>
                    </h1>
                    <Link to="/" className="btn btn-primary">
                        <Trans i18nKey="cartEmptyButton"/>
                    </Link>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-9">
                        { cartContext.cart.map((item) => (
                            <CartCard
                                key={ item.productId }
                                product={ cartContext.products.find((product) => product.id === item.productId) }
                                amount={ item.amount }
                                addToCart={ cartContext.addToCart }
                            />
                        )) }
                        <CartPriceSummary/>
                    </div>
                    <div className="col-md-3">
                        <Link to="/order/data" className="btn btn-success pl-4 pr-4 pt-2 pb-2 mr-2">
                            <Trans i18nKey="cartOrderButton"/>
                        </Link>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(CartPageContent);
