import React from "react";

const OrderContext = React.createContext(null);

export class OrderManager extends React.Component {
    getDefaultDeliveryInfo() {
        return {
            company: false,
            companyName: "",

            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryStreet: "",
            deliveryHouseNumber: "",
            deliveryPostalCode: "",
            deliveryCity: "",

            invoiceEqualsDelivery: true,
            invoiceFirstName: "",
            invoiceLastName: "",
            invoiceStreet: "",
            invoiceHouseNumber: "",
            invoicePostalCode: "",
            invoiceCity: "",

            email: "",
            phoneNumber: ""
        };
    }

    constructor(props) {
        super(props);
        const deliveryInfo = localStorage.deliveryInfo !== undefined ? JSON.parse(localStorage.deliveryInfo) : this.getDefaultDeliveryInfo();
        this.state = {
            orderContext: {
                deliveryInfo,
                updateDeliveryInfo: this.updateDeliveryInfo.bind(this),
                isDeliveryInfoValid: this.isDeliveryInfoValid.bind(this)
            }
        }
    }

    updateOrderContext(state) {
        this.setState(previousState => {
            return { orderContext: { ...previousState.orderContext, ...state } }
        });
    }

    setDeliveryInfo(deliveryInfo) {
        localStorage.deliveryInfo = JSON.stringify(deliveryInfo);
        this.updateOrderContext({ deliveryInfo: deliveryInfo });
    }

    updateDeliveryInfo(updatedField) {
        let deliveryInfo = this.state.orderContext.deliveryInfo;
        this.setDeliveryInfo({ ...deliveryInfo, ...updatedField })
    }

    isDeliveryInfoValid() {
        const state = this.state.orderContext.deliveryInfo;
        if(state.company && state.companyName.length === 0) return false;
        if(state.deliveryFirstName.length === 0) return false;
        if(state.deliveryLastName.length === 0) return false;
        if(state.deliveryStreet.length === 0) return false;
        if(state.deliveryHouseNumber.length === 0) return false;
        if(state.deliveryPostalCode.length === 0) return false;
        if(state.deliveryCity.length === 0) return false;

        if(!state.invoiceEqualsDelivery) {
            if(state.invoiceFirstName.length === 0) return false;
            if(state.invoiceLastName.length === 0) return false;
            if(state.invoiceStreet.length === 0) return false;
            if(state.invoiceHouseNumber.length === 0) return false;
            if(state.invoicePostalCode.length === 0) return false;
            if(state.invoiceCity.length === 0) return false;
        }

        if(state.email.length === 0) return false;
        if(state.phoneNumber.length === 0) return false;

        return true;
    }

    render() {
        return (
            <OrderContext.Provider value={ this.state.orderContext }>
                {this.props.children}
            </OrderContext.Provider>
        )
    }
}

export default OrderContext;
