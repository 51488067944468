import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";
import CartContext from "../context/internal/CartManager";

function CartPriceSummary() {
    const cart = useContext(CartContext);
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-end text-right mb-2">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="shippingCosts"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="free"/>
                        </b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="vat"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>&euro; { cart.getTotalTax().toFixed(2).replace(".", ",") }</b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="totalPrice"/>
                        </b>
                        <br/>
                        <span className="text-muted">
                            <Trans i18nKey="includingVat"/>
                        </span>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>&euro; { cart.getTotalPrice().toFixed(2).replace(".", ",") }</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartPriceSummary;
