import React from "react";

import Helmet from "../../components/Helmet";
import CartPageContent from "../../shopcrate-framework/page-content/CartPageContent";

function Cart() {
    return (
        <React.Fragment>
            <Helmet title="Winkelwagen"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Winkelwagen</h1>
                </div>
            </div>
            <div className="container">
                <CartPageContent/>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Cart);
