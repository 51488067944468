import React, {
    useContext
} from "react";
import i18next from "i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";

function ProductLimitPerOrderNotice() {
    const product = useContext(ProductPageContext);
    if(!product.product || product.product.limitPerOrder === null || product.product.limitPerOrder <= 0) {
        return null;
    }
    return i18next.t("maxPerOrderNotice").replace("{amount}", product.product.limitPerOrder);
}

export default React.memo(ProductLimitPerOrderNotice);
