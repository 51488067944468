import React, {
    useEffect,
    useState
} from "react";

const sizes = {
    "75x75": {
        1: "75x75",
        2: "150x150",
        3: "225x225"
    },
    "175x130": {
        1: "175x130",
        2: "350x260",
        3: "525x390"
    },
    "350x260": {
        1: "350x260",
        2: "350x260",
        3: "525x390"
    }
}

function ProductImage({ image, size = "original", alt, ...props }) {
    const [imageUrl, setImageUrl] = useState("");
    const [pixelRatio, setPixelRatio] = useState(Math.ceil(window.devicePixelRatio));

    useEffect(() => {
        const updateImageUrl = () => {
            if(!image) {
                setImageUrl(null);
            } else if(size === "original") {
                setImageUrl(image.url);
            } else {
                const sizeData = sizes[size];
                if(sizeData) {
                    let found = false;
                    for (const [key, value] of Object.entries(sizeData)) {
                        if(parseInt(key) >= window.devicePixelRatio) {
                            const alternativeImage = image.alternatives[value];
                            if(alternativeImage) {
                                setImageUrl(alternativeImage.url);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(!found) {
                        for(const key of Object.keys(sizeData).sort((k1, k2) => { return k1 > k2 ? -1 : 1 })) {
                            const value = sizeData[key];
                            const alternativeImage = image.alternatives[value];
                            if(alternativeImage) {
                                setImageUrl(alternativeImage.url);
                                break;
                            }
                        }
                    }
                }
            }
        }

        const updatePixelRatioIfNeeded = () => {
            const currentPixelRatio = Math.ceil(window.devicePixelRatio);
            if(currentPixelRatio !== pixelRatio) {
                setPixelRatio(currentPixelRatio);
                updateImageUrl();
            }
        }

        updatePixelRatioIfNeeded();
        updateImageUrl();

        window.addEventListener("resize", updatePixelRatioIfNeeded);
        return () => window.removeEventListener("resize", updatePixelRatioIfNeeded);
    }, [pixelRatio, image, size]);

    const placeholder = size === "original" ? "https://via.placeholder.com/175x130" : "https://via.placeholder.com/" + size;

    return (
        <img
            src={ imageUrl ? imageUrl : placeholder }
            alt={ alt }
            { ...props }
        />
    )
}

export default ProductImage;
