import React from "react";
import Helmet from "../components/Helmet";
import Page404 from "./Page404";
import DefaultCategoryPage from "../shopcrate-framework/page-content/category/DefaultCategoryPage";

function Category() {
    return (
        <DefaultCategoryPage
            error404Page={ <Page404/> }
            helmet={ (categoryContext) => (
                <Helmet title={ categoryContext.category ? categoryContext.category.name : null }/>
            )}
        />
    );
}

export default React.memo(Category);
