import React from "react";
import {
    BrowserRouter as Router
} from "react-router-dom";
import ShopCrateManager from "./shopcrate-framework/context/ShopCrateManager";
import ShopRouter from "./ShopRouter";

import "./scss/main.scss";

function App() {
    return (
        <ShopCrateManager>
            <Router>
                <ShopRouter/>
            </Router>
        </ShopCrateManager>
    );
}

export default App;
