import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    Redirect,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../context/internal/CartManager";
import OrderContext from "../context/internal/OrderManager";
import PaymentMethodContext from "../context/internal/PaymentMethodManager";
import Loading from "../../shopcrate-framework/components/Loading";
import SteppedProgressBar from "../../shopcrate-framework/components/progressbar/SteppedProgressBar";

function PaymentMethodSelectionPageContent() {
    const history = useHistory();
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);
    const paymentMethodContext = useContext(PaymentMethodContext);

    const [error, setError] = useState(null);

    useEffect(() => {
        if(paymentMethodContext.paymentMethods === null) {
            paymentMethodContext.getPaymentMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toNextStep = () => {
        if(!paymentMethodContext.isPaymentMethodValid()) {
            setError(i18next.t("errorInvalidPaymentMethodSelected"));
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        history.push("/order/overview");
    };

    if(!order.isDeliveryInfoValid()) {
        return (
            <Redirect to="/order/data"/>
        );
    }
    if(cart.cart === null) {
        return (
            <Loading/>
        );
    }
    if(cart.cart.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }
    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="cart"/> } to="/cart" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to="/order/data" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to="/order/paymentmethod" active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to="/order/overview"/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { paymentMethodContext.paymentMethodsError ? (
                <Alert variant="danger">{ paymentMethodContext.paymentMethodsError }</Alert>
            ) : !paymentMethodContext.paymentMethods ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    { paymentMethodContext.paymentMethods.map((paymentMethod) => {
                        const selected = paymentMethodContext.paymentMethod === paymentMethod.id;
                        const icon = paymentMethod.image.svg;
                        const onClick = () => {
                            paymentMethodContext.setPaymentMethod(paymentMethod.id);
                        };
                        return (
                            <div
                                key={ paymentMethod.id }
                                className={ "card pointer-cursor mb-2" + (selected ? " border-primary" : "") }
                                onClick={ onClick }
                            >
                                <div className="card-body">
                                    <div className="custom-control custom-radio custom-radio-lg">
                                        <input
                                            type="radio"
                                            id={ paymentMethod.id }
                                            name={ paymentMethod.id }
                                            className="custom-control-input"
                                            checked={ selected }
                                            onChange={ onClick }
                                        />
                                        <label className="custom-control-label pointer-cursor pl-2" htmlFor={ paymentMethod.id }>
                                            <img src={ icon } height="48px" alt={ paymentMethod.description }/>
                                            <b className="mt-1 ml-3">{ paymentMethod.description }</b>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className="card mb-3">
                        <div className="card-body">
                            <Link to="/order/data" className="btn btn-link">
                                <i className="fas fa-chevron-left mr-2"/>
                                <Trans i18nKey="previousStep"/>
                            </Link>
                            <div className="float-right">
                                <Button variant="success" onClick={ toNextStep }>
                                	<Trans i18nKey="nextStep"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}


        </React.Fragment>
    );
}

export default React.memo(PaymentMethodSelectionPageContent);
