import React, {
    useContext
} from "react";
import {
    CategoryPageContext
} from "../../context/CategoryPageManager";
import Skeleton from "react-loading-skeleton";
import ProductCard from "../product/ProductCard";

function CategoryPageProducts() {
    const categoryContext = useContext(CategoryPageContext);
    return (
        <div className="row">
            { categoryContext.category ? categoryContext.category.products.map((product, index) => (
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4" key={ index }>
                    <ProductCard product={ product } size="large"/>
                </div>
            )) : [...Array(6)].map((value, index) => (
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4" key={ index }>
                    <Skeleton height={ 250 }/>
                        <div className="mb-1">
                            <Skeleton height={ 19 } width={ 100 }/>
                        </div>
                    <Skeleton height={ 19 } width={ 60 }/>
                </div>
            ))}
        </div>
    )
}

export default React.memo(CategoryPageProducts);
