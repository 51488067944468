import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import shopcrateApi from "../ShopCrateAPI";
import CartContext from "../context/internal/CartManager";
import OrderContext from "../context/internal/OrderManager";
import PaymentMethodContext from "../context/internal/PaymentMethodManager";
import Loading from "../../shopcrate-framework/components/Loading";
import CartCard from "../../shopcrate-framework/components/CartCard";
import CartPriceSummary from "../../shopcrate-framework/components/CartPriceSummary";
import SteppedProgressBar from "../../shopcrate-framework/components/progressbar/SteppedProgressBar";

function OrderOverviewPageContent() {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);
    const paymentMethodContext = useContext(PaymentMethodContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const placeOrder = () => {
        setLoading(true);
        setError(null);
        shopcrateApi.post("/placeOrder", {
            deliveryInfo: JSON.stringify(order.deliveryInfo),
            cart: JSON.stringify(cart.cart),
            paymentMethod: paymentMethodContext.paymentMethod,
            language: i18next.language
        })
            .then((response) => {
                if(response.data.valid) {
                    window.location = response.data.checkoutUrl;
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch(() => {
                setError(i18next.t("errorGeneral"));
                setLoading(false);
            });
    }

    useEffect(() => {
        if(paymentMethodContext.paymentMethods === null) {
            paymentMethodContext.getPaymentMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(cart.cart === null) {
        return (
            <Loading/>
        );
    }
    if(cart.cart.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }
    if(!order.isDeliveryInfoValid()) {
        return (
            <Redirect to={ "/order/data" }/>
        );
    }
    if(paymentMethodContext.paymentMethods && !paymentMethodContext.isPaymentMethodValid()) {
        return (
            <Redirect to="/order/paymentmethod"/>
        );
    }

    const paymentMethod = !paymentMethodContext.paymentMethods ? null : paymentMethodContext.paymentMethods.find((paymentMethod) => {
        return paymentMethod.id === paymentMethodContext.paymentMethod;
    });

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="cart"/> } to="/cart" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to="/order/data" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to="/order/paymentmethod" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to="/order/overview" active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { paymentMethodContext.paymentMethodsError ? (
                <Alert variant="danger">{ paymentMethodContext.paymentMethodsError }</Alert>
            ) : !paymentMethodContext.paymentMethods ? (
                <Loading/>
            ) : loading ? (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4>
                            <Trans i18nKey="redirectPayment"/>
                        </h4>
                        <Loading/>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="contactInformation"/>
                                        <Link to="/order/data" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { order.deliveryInfo.email }
                                        <br/>
                                        { order.deliveryInfo.phoneNumber }
                                    </p>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        { order.deliveryInfo.invoiceEqualsDelivery ? (
                                            <Trans i18nKey="deliveryInvoiceAddress"/>
                                        ) : (
                                            <Trans i18nKey="deliveryAddress"/>
                                        )}
                                        <Link to="/order/data" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { order.deliveryInfo.deliveryFirstName + " " + order.deliveryInfo.deliveryLastName }
                                        <br/>
                                        { order.deliveryInfo.deliveryStreet + " " + order.deliveryInfo.deliveryHouseNumber }
                                        <br/>
                                        { order.deliveryInfo.deliveryPostalCode + ", " + order.deliveryInfo.deliveryCity }
                                    </p>
                                </div>
                            </div>
                            { !order.deliveryInfo.invoiceEqualsDelivery && (
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Trans i18nKey="invoiceAddress"/>
                                            <Link to="/order/data" className="btn btn-link btn-sm">
                                                <Trans i18nKey="edit"/>
                                            </Link>
                                        </h5>
                                        <p className="card-text">
                                            { order.deliveryInfo.invoiceFirstName + " " + order.deliveryInfo.invoiceLastName }
                                            <br/>
                                            { order.deliveryInfo.invoiceStreet + " " + order.deliveryInfo.invoiceHouseNumber }
                                            <br/>
                                            { order.deliveryInfo.invoicePostalCode + ", " + order.deliveryInfo.invoiceCity }
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="paymentMethod"/>
                                        <Link to="/order/paymentmethod" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <div className="d-flex">
                                        <img src={ paymentMethod.image.svg } height="24px" alt="Icon"/>
                                        <span className="d-inline ml-2">
                                            { paymentMethod.description }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="cart"/>
                                        <Link to="/cart" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    { cart.products === null ? (
                                        <Loading/>
                                    ) : (
                                        <React.Fragment>
                                            {cart.cart.map((item) => (
                                                <CartCard
                                                    key={ item.productId }
                                                    product={ cart.products.find((product) => product.id === item.productId) }
                                                    amount={ item.amount }
                                                    noLink
                                                    hideImage
                                                />
                                            ))}
                                            <CartPriceSummary/>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <Link to="/order/paymentmethod" className="btn btn-link">
                                <i className="fas fa-chevron-left mr-2"/>
                                <Trans i18nKey="previousStep"/>
                            </Link>
                            <div className="float-right">
                                <button className="btn btn-success" onClick={ placeOrder }>
                                    <Trans i18nKey="pay"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(OrderOverviewPageContent);
