import React from "react";
import i18next from "i18next";
import shopcrateApi from "../../ShopCrateAPI";

const CartContext = React.createContext(null);

export class CartManager extends React.Component {
    state = {
        cartContext: {
            cart: null,
            products: null,
            error: null,
            addToCart: this.addToCart.bind(this),
            getTotalPrice: this.getTotalPrice.bind(this),
            getTotalTax: this.getTotalTax.bind(this),
            clearCart: this.clearCart.bind(this)
        }
    }

    updateCartContext(state) {
        this.setState(previousState => {
            return { cartContext: { ...previousState.cartContext, ...state } }
        });
    }

    componentDidMount() {
        if(localStorage.cart === undefined) {
            this.setCart([]);
        }
        const cart = JSON.parse(localStorage.cart);
        this.updateCartContext({ cart: cart });
        if(cart.length > 0) {
            this.getProducts(cart);
        } else {
            this.updateCartContext({ products: [] });
        }
    }

    setCart(cart) {
        localStorage.cart = JSON.stringify(cart);
        this.updateCartContext({ cart: cart });
    }

    addToCart(productId, amount) {
        let cart = this.state.cartContext.cart;
        const productItemIndex = cart.findIndex((item) => {
            return item.productId === productId;
        });
        const product = !this.state.cartContext.products ? null : this.state.cartContext.products.find((productSearch) => {
            return productSearch.id === productId;
        });
        const hardcodedLimit = 99;
        const productAmountLimit = product ? (product.limitPerOrder ? product.limitPerOrder : hardcodedLimit) : hardcodedLimit;
        let productItem;
        if(productItemIndex !== -1) {
            productItem = cart[productItemIndex];
            productItem.amount = Math.min(productAmountLimit, productItem.amount + amount);
            if(productItem.amount > 0) {
                cart[productItemIndex] = productItem;
            } else {
                cart.splice(productItemIndex, 1);
            }
        } else {
            if(amount <= 0) {
                return;
            }
            cart.push({
                productId: productId,
                amount: Math.max(1, Math.min(productAmountLimit, amount))
            });
            this.getProducts(cart);
        }
        this.setCart(cart);
    }

    clearCart() {
        this.setCart([]);
    }

    getTotalPrice() {
        let price = 0;
        this.state.cartContext.cart.forEach((item) => {
            const product = this.state.cartContext.products.find((product) => product.id === item.productId);
            if(product !== undefined) {
                price += item.amount * product.price;
            }
        });
        return price;
    }

    getTotalTax() {
        let tax = 0;
        this.state.cartContext.cart.forEach((item) => {
            const product = this.state.cartContext.products.find((product) => product.id === item.productId);
            if(product !== undefined) {
                tax += item.amount * product.price * product.taxPercentage;
            }
        });
        return tax;
    }

    getProducts(cart) {
        this.updateCartContext({ products: null });
        let productIds = [];
        cart.forEach((item) => {
            productIds.push(item.productId);
        });
        shopcrateApi.post("/getProducts", { productIds: productIds.join(",") })
            .then((response) => {
                if(response.data.valid) {
                    const products = response.data.products;
                    this.updateCartContext({ products });
                    this.removeNonOrderableProducts(products);
                } else {
                    this.updateCartContext({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.updateCartContext({ error: i18next.t("errorGeneral") });
                console.error(error);
            });
    }

    removeNonOrderableProducts(inlineProducts) {
        const products = inlineProducts ? inlineProducts : this.state.cartContext.products;
        for(let i = 0; i < this.state.cartContext.cart.length; i++) {
            const {productId, amount} = this.state.cartContext.cart[i];
            const product = products.find((product) => product.id === productId);
            if(!product) {
                continue;
            }
            if(!product.orderable) {
                this.addToCart(productId, -amount);
            }
        }
    }

    render() {
        return (
            <CartContext.Provider value={ this.state.cartContext }>
                {this.props.children}
            </CartContext.Provider>
        )
    }
}

export default CartContext;
