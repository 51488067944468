import React from "react";

import Helmet from "../components/Helmet";
import HomeProductCollectionList from "../components/home-collection/HomeProductCollectionList";

function Assortiment() {
    return (
        <React.Fragment>
            <Helmet title="Assortiment"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Assortiment</h1>
                </div>
            </div>
            <div className="container mb-5">
                <HomeProductCollectionList collection="home-collection-valk-rozen" noTitle/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Assortiment);
