import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Skeleton from "react-loading-skeleton";

function ProductPagePrice() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product) {
        return (
            <Skeleton/>
        )
    }
    return (
        <React.Fragment>
            &euro; { productContext.getFormattedPrice().price },
            <sup style={{marginLeft: "-5px"}}><small>{ productContext.getFormattedPrice().cents }</small></sup>
        </React.Fragment>
    )
}

export default React.memo(ProductPagePrice);
