import React from "react";
import {
    Link
} from "react-router-dom";
import i18next from "i18next";

import ProductImage from "./ProductImage";

function CartCard(props) {
    const productImage = (
        <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
            <ProductImage
                image={ props.product ? props.product.images[0] : null }
                size="175x130"
                className="img-fluid rounded"
                alt="Product"
                style={{ maxHeight: "120px" }}
            />
        </div>
    );

    const price = props.product.orderPrice ? props.product.orderPrice : props.product.price;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="row">
                    { !props.hideImage && (
                        <div className="col-md-3">
                            { props.noLink ? productImage : (
                                <Link to={ "/product/" + props.product.id + "/" + props.product.url }>
                                    { productImage }
                                </Link>
                            )}
                        </div>
                    )}
                    <div className="col">
                        <h5 className="card-title">
                            { props.noLink ? props.product.name : (
                                <Link to={ "/product/" + props.product.id + "/" + props.product.url }>
                                    { props.product.name }
                                </Link>
                            )}
                        </h5>
                        <span className="float-right">
                            <b>&euro; { (props.amount * price).toFixed(2).replace(".", ",") }</b>
                        </span>
                        { props.addToCart !== undefined && (
                            <React.Fragment>
                                <div className="btn-toolbar">
                                    <div className="btn-group mr-2">
                                        <button className="btn btn-danger" onClick={ () => props.addToCart(props.product.id, -1) }>
                                            <i className="fas fa-minus"/>
                                        </button>
                                        <input
                                            type="number" className="form-control" placeholder="Aantal"
                                            style={{ borderRadius: 0, width: "75px", textAlign: "center" }}
                                            value={ props.amount }
                                            onChange={ (event) => props.addToCart(props.product.id, props.amount - event.target.value) }
                                        />
                                        <button className="btn btn-success" onClick={ () => props.addToCart(props.product.id, 1) }>
                                            <i className="fas fa-plus"/>
                                        </button>
                                    </div>
                                    <div className="btn-group">
                                        <button className="btn btn-danger" onClick={ () => props.addToCart(props.product.id, -props.amount) }>
                                            <i className="fas fa-trash"/>
                                        </button>
                                    </div>
                                </div>
                                { props.product.limitPerOrder && props.amount >= props.product.limitPerOrder && (
                                    <div className="text-muted mt-2">
                                        { i18next.t("maxPerOrderNotice").replace("{amount}", props.product.limitPerOrder) }
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CartCard);
