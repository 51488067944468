import React from "react";
import CategoryPageManager, {
    CategoryPageContext
} from "../../context/CategoryPageManager";

import CategoryPageTitle from "../../components/category/CategoryPageTitle";
import CategoryPageProducts from "../../components/category/CategoryPageProducts";

function DefaultProductPage(props) {
    return (
        <CategoryPageManager { ...props }>
            { props.helmet && (
                <CategoryPageContext.Consumer>
                    { categoryContext => props.helmet(categoryContext) }
                </CategoryPageContext.Consumer>
            )}
            <div className="container mt-5 mb-5">
                <CategoryPageTitle/>
                <CategoryPageProducts/>
            </div>
        </CategoryPageManager>
    )
}

export default React.memo(DefaultProductPage);
