import React from "react";
import ReactMarkdown from "react-markdown";
import YouTube from "react-youtube";
import {
    Trans
} from "react-i18next";

import CartCard from "../../components/CartCard";
import isYouTube from "../../components/isYouTube";

function DefaultOrderSuccessPage({ order }) {
    if(!order) {
        return null;
    }
    let containsDigitalProducts = false;
    let containsPhysicalProducts = false;
    let links = [];
    for(const product of order.products) {
        if(product.digital) {
            containsDigitalProducts = true;
            for(const link of product.productLinks) {
                links.push(link);
            }
        }
        if(product.physical) {
            containsPhysicalProducts = true;
        }
    }

    const thankYouCard = (
        <div className="card mb-3">
            <div className="card-body text-center">
                <i className="fad fa-box-alt text-primary mb-3" style={{ fontSize: "4rem" }}/>
                <h4><Trans i18nKey="orderCompleteTitle"/></h4>
                { containsDigitalProducts && containsPhysicalProducts ? (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionPhysicalDigital"/></p>
                ) : containsPhysicalProducts ? (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionPhysical"/></p>
                ) : containsDigitalProducts && (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionDigital"/></p>
                )}
            </div>
        </div>
    );
    const productsCard = (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    <Trans i18nKey="products"/>
                </h5>
                { order.products.map((product) => (
                    <CartCard
                        key={ product.id }
                        product={ product }
                        amount={ product.amount }
                        hideImage={ containsDigitalProducts }
                        noLink
                    />
                ))}
            </div>
        </div>
    );
    const linksList = links.map((link, index) => (
        <div className="card mb-3" key={ index }>
            <div className="card-body">
                <h5 className="card-title">
                    { link.title }
                </h5>
                <ReactMarkdown source={ link.description }/>
                { link.url && link.url.length > 0 && (
                    <React.Fragment>
                        { isYouTube(link.url) ? (
                            <div className="row">
                                <div className="col-md-10 col-lg-8 col-xl-7">
                                    <YouTube
                                        videoId={ isYouTube(link.url) }
                                        className="embed-responsive embed-responsive-16by9"
                                    />
                                </div>
                            </div>
                        ) : (
                            <a href={ link.url } className="btn btn-primary" target="_blank" rel="noreferrer noopener">
                                <Trans i18nKey="openLink"/>
                            </a>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    ));

    if(!containsDigitalProducts) {
        return (
            <React.Fragment>
                { thankYouCard }
                { productsCard }
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        { thankYouCard }
                    </div>
                    <div className="col-md-6">
                        { productsCard }
                    </div>
                </div>
                { linksList }
            </React.Fragment>
        );
    }
}

export default React.memo(DefaultOrderSuccessPage);
