import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Navbar from "./components/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";

import Assortiment from "./pages/Assortiment";
import OverOns from "./pages/OverOns";
import Contact from "./pages/Contact";

import Product from "./pages/Product";
import Category from "./pages/Category";
import Page404 from "./pages/Page404";

import Cart from "./pages/order/Cart";
import DeliveryInfo from "./pages/order/DeliveryInfo";
import PaymentMethodSelection from "./pages/order/PaymentMethodSelection";
import OrderOverview from "./pages/order/OrderOverview";

import OrderStatus from "./pages/order/OrderStatus";

function ShopRouter() {
    return (
        <React.Fragment>
            <Navbar/>
            <ScrollToTop/>

            <Switch>
                <Route path="/" exact component={OverOns}/>
                <Route path="/assortiment" exact component={Assortiment}/>
                <Route path="/contact" exact component={Contact}/>

                <Route path={[
                    "/product/:productId",
                    "/product/:productId/:productUrl"
                ]} exact component={Product}/>
                <Route path={[
                    "/category/:categoryId",
                    "/category/:categoryId/:categoryUrl"
                ]} exact component={Category}/>
                <Route path="/cart" exact component={Cart}/>
                <Route path="/order/data" exact component={DeliveryInfo}/>
                <Route path="/order/paymentmethod" exact component={PaymentMethodSelection}/>
                <Route path="/order/overview" exact component={OrderOverview}/>
                <Route path="/order/:orderToken" exact component={OrderStatus}/>

                <Route path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                    <Redirect to="/"/>
                </Route>
                <Route path={["/", "/404"]} compoent={Page404}/>
            </Switch>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
