import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    Trans
} from "react-i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import CartContext from "../../context/internal/CartManager";

function ProductPageAddToCardButton({ buttonVariant = "success" }) {
    const productContext = useContext(ProductPageContext);
    const cartContext = useContext(CartContext);
    if(!productContext.product) {
        return (
            <Skeleton height={ 42 } width={ 185 }/>
        )
    }
    if(!productContext.product.orderable) {
        return (
            <button
                className={ "btn btn-" + buttonVariant + " pl-4 pr-4 pt-2 pb-2" }
                disabled
            >
                <i className="fas fa-shopping-cart mr-2"/>
                <Trans i18nKey="soldOut"/>
            </button>
        )
    }
    return (
        <button
            className={ "btn btn-" + buttonVariant + " pl-4 pr-4 pt-2 pb-2" }
            onClick={ () => {
                cartContext.addToCart(productContext.product.id, 1);
                productContext.triggerAddedToCardModal();
            }}
        >
            <i className="fas fa-cart-arrow-down mr-2"/>
            <Trans i18nKey="addToCart"/>
        </button>
    )
}

export default React.memo(ProductPageAddToCardButton);
