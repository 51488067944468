import React from "react";
import Helmet from "../components/Helmet";

function Page404() {
    return (
        <React.Fragment>
            <Helmet title="404 Not Found"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>404 Not Found</h1>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Page404;
