import React from "react";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ProductCollectionBase from "../../shopcrate-framework/components/collection/ProductCollectionBase";
import HomeProductCard from "./HomeProductCard";

class HomeProductCollectionList extends ProductCollectionBase {
    getProductCard(product) {
        return (
            <div>
                <HomeProductCard product={ product }/>
            </div>
        )
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.collection === null) {
            return (
                <div className="mb-3">
                    { !this.props.noTitle && (
                        <Skeleton height={ 30 } width={ 200 }/>
                    )}
                    <div>
                        {[...Array(6)].map((value, index) => (
                            <div className="row mb-5" key={ index }>
                                <div className="col-12 col-md-4">
                                    <h2 className="text-primary">
                                        <Skeleton/>
                                    </h2>
                                    <div style={{ fontSize: "1.1rem", fontWeight: 450, color: "#1e2c1e" }}>
                                        <Skeleton count={ 3 }/>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4">
                                    <Skeleton style={{ aspectRatio: "1/1" }}/>
                                </div>
                                <div className="col-6 col-md-4">
                                    <Skeleton style={{ aspectRatio: "1/1" }}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
        return (
            <React.Fragment>
                { !this.props.noTitle && (
                    <h3>{ this.state.collection.name }</h3>
                )}
                { this.state.collection.products.map((product, index) => {
                    const isLast = index === this.state.collection.products.length - 1;
                    return (
                        <React.Fragment key={ index }>
                            { this.getProductCard(product) }
                            { !isLast && (
                                <hr className="my-5 bg-primary" style={{ borderWidth: "3px" }}/>
                            )}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }
}

export default HomeProductCollectionList;
