import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Skeleton from "react-loading-skeleton";

function ProductPageTitle({ showSubtitle = true }) {
    const productContext = useContext(ProductPageContext);
    return (
       <React.Fragment>
           <h1 className="mb-0">
               { productContext.product ? productContext.product.name : (
                   <Skeleton/>
               )}
           </h1>
           { showSubtitle && productContext.product && productContext.product.subtitle && (
               <h3 className="text-muted mt-0">
                   <small>{ productContext.product.subtitle }</small>
               </h3>
           )}
       </React.Fragment>
    )
}

export default React.memo(ProductPageTitle);
