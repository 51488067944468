import React from "react";
import i18next from "i18next";
import shopcrateApi from "../../ShopCrateAPI";

const PaymentMethodContext = React.createContext(null);

export class PaymentMethodManager extends React.Component {
    constructor(props) {
        super(props);
        const paymentMethod = localStorage.paymentMethod !== undefined ? localStorage.paymentMethod : null;
        this.state = {
            paymentMethodContext: {
                paymentMethod,
                paymentMethods: null,
                paymentMethodsError: null,
                getPaymentMethods: this.getPaymentMethods.bind(this),
                setPaymentMethod: this.setPaymentMethod.bind(this),
                doesDeviceSupportApplePay: this.doesDeviceSupportApplePay.bind(this),
                isPaymentMethodValid: this.isPaymentMethodValid.bind(this)
            }
        }
    }

    updatePaymentMethodContext(state) {
        this.setState(previousState => {
            return { paymentMethodContext: { ...previousState.paymentMethodContext, ...state } }
        });
    }

    setPaymentMethod(paymentMethod) {
        localStorage.paymentMethod = paymentMethod;
        this.updatePaymentMethodContext({ paymentMethod });
    }

    /// Check whether the current browser supports Apple Pay.
    doesDeviceSupportApplePay() {
        return window.ApplePaySession && window.ApplePaySession.canMakePayments();
    }

    isPaymentMethodValid() {
        const selectedPaymentMethod = this.state.paymentMethodContext.paymentMethod;
        if(!selectedPaymentMethod) {
            return false;
        }
        if(!this.state.paymentMethodContext.paymentMethods) {
            return null;
        }
        const index = this.state.paymentMethodContext.paymentMethods.findIndex((paymentMethod) => {
            return paymentMethod.id ===  selectedPaymentMethod;
        })
        return index !== -1;
    }

    getPaymentMethods() {
        shopcrateApi.get("/getPaymentMethods")
            .then((response) => {
                if(response.data.valid) {
                    const paymentMethods = this.filterSupportedPaymentMethods(Object.values(response.data.paymentMethods));
                    this.updatePaymentMethodContext({ paymentMethods });
                    if(paymentMethods.length === 1) {
                        this.setPaymentMethod(paymentMethods[0].id);
                    }
                } else {
                    this.updatePaymentMethodContext({ paymentMethodsError: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.updatePaymentMethodContext({ paymentMethodsError: i18next.t("errorGeneral") });
            });
    }

    filterSupportedPaymentMethods(allPaymentMethods) {
        return allPaymentMethods.filter((paymentMethod) => {
            return !(paymentMethod.id === "applepay" && !this.doesDeviceSupportApplePay());
        })
    }

    render() {
        return (
            <PaymentMethodContext.Provider value={ this.state.paymentMethodContext }>
                {this.props.children}
            </PaymentMethodContext.Provider>
        )
    }
}

export default PaymentMethodContext;
