import React from "react";
import DefaultProductPage from "../shopcrate-framework/page-content/product/DefaultProductPage";
import Helmet from "../components/Helmet";
import Page404 from "./Page404";

function Product() {
    return (
        <DefaultProductPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    );
}

export default React.memo(Product);
