import React from "react";
import ReactMarkdown from "react-markdown";

function HomeProductCard({ product }) {
    let images = [];
    if(product.images.length >= 1) {
        images.push(product.images[0]);
    }
    if(product.images.length >= 2) {
        images.push(product.images[1]);
    }
    return (
        <div className="row">
            <div className="col-12 col-md-4 text-justify">
                <h2 className="text-primary">{ product.name }</h2>
                <div style={{ fontSize: "1.1rem", fontWeight: 450 }}>
                    <ReactMarkdown>
                        { product.description }
                    </ReactMarkdown>
                </div>
            </div>
            { images.map((image) => {
                return (
                    <div className="col-6 col-md-4" key={ image.id }>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: "white",
                                aspectRatio: "1/1"
                            }}
                        >
                            <img
                                src={ image.url }
                                alt={ product.name }
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default React.memo(HomeProductCard);
