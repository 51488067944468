import React from "react";

import Helmet from "../components/Helmet";

function Contact() {
    return (
        <React.Fragment>
            <Helmet title="Over Ons"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Contact</h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md">
                        <p className="mt-4 mb-5" style={{ fontSize: "20px" }}>
                            <span className="h1">Valk Rozen</span><br/>
                            Pieter Bregmanlaan 14<br/>
                            2641 PS Pijnacker<br/>
                            The Netherlands<br/>
                            <br/>
                            Telefoon: <a href="tel:+31153613158">+31 (0)15 361 3158</a><br/>
                            Email: <a href="mailto:info@valkrozen.nl">info@valkrozen.nl</a><br/>
                            KvK: 27218507
                        </p>
                    </div>
                    <div className="col-md">
                        <p className="mt-4 mb-5" style={{ fontSize: "20px" }}>
                            <span className="h1">Openingstijden stalletje</span><br/>
                            Elke dag van 9:00 tot 20:00
                        </p>
                    </div>
                </div>
            </div>
            <iframe
                id="map" title="map" width="100%" height="400" frameBorder="0" style={ { border: 0 } } allowFullScreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.8808966147385!2d4.464104616009829!3d52.02732117972394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5c9672d824373%3A0x6de902e9743aac0d!2sValk%20Rozen!5e1!3m2!1snl!2snl!4v1647286275464!5m2!1snl!2snl"
            />
        </React.Fragment>
    );
}

export default React.memo(Contact);
