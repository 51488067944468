import React from "react";

import Helmet from "../../components/Helmet";
import OrderOverviewPageContent from "../../shopcrate-framework/page-content/OrderOverviewPageContent";

function OrderOverview() {
    return (
        <React.Fragment>
            <Helmet title="Overzicht"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Overzicht</h1>
                </div>
            </div>
            <div className="container">
                <OrderOverviewPageContent/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(OrderOverview);
