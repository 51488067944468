import React from "react";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";

class ProductCollectionBase extends React.PureComponent {
    state = {
        collection: null,
        error: null
    }

    componentDidMount() {
        this.getCollection(this.props.collection);
    }

    getCollection(codeName) {
        this.setState({ collection: null, error: null });
        shopcrateApi.post("/getCollection", { codeName: codeName })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ collection: response.data.collection });
                } else {
                    this.setState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setState({ error: i18next.t("errorGeneral") });
            })
    }

    render() {
        return (
            <p>render() not implemented!</p>
        )
    }
}

export default ProductCollectionBase;
