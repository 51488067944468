import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../components/Helmet";

import tuin from "../img/valk-rozen-tuin.jpg";
import stalletje from "../img/stalletje.jpg";

function OverOns() {
    return (
        <React.Fragment>
            <Helmet/>
            <div className="jumbotron jumbotron-fluid jumbotron-header d-flex flex-column no-darken py-3" style={{minHeight: "400px"}}/>
            <div className="container text-justify">
                <div className="mb-5" style={{ fontSize: "1.2rem", fontWeight: 500 }}>
                    <p>
                        <span className="h2 text-primary">Wij zijn Valk Rozen,</span>
                        <br/>
                        een bedrijf waar al tientallen jaren lang met veel passie de
                        mooiste rozen worden gekweekt. Niet alleen voor de export via Royal Flora Holland, maar ook
                        voor particuliere verkoop aan iedereen die kiest voor kwaliteit.
                    </p>
                </div>
                <div className="row">
                    <div className="col mb-5">
                        <img src={ tuin } alt="Valk Rozen" className="img-fluid"/>
                    </div>
                    <div className="col-md-7" style={{ fontSize: "1.2rem", fontWeight: 500 }}>
                        <p>
                            Wij zijn een familiebedrijf dat zich onderscheidt door alle rassen zelf te kweken en de
                            mogelijkheid van maatwerk te bieden. Bent u op zoek naar iets bijzonders (mengbossen, per
                            stuk verpakt of juist een mega-grote bos), vraag het ons gerust!
                        </p>
                        <p>
                            Ons hoofdras is al 20 jaar lang de hardroze Aqua!, maar voor de particuliere verkoop hebben
                            we ook een aantal andere rassen in ons assortiment opgenomen. Hierbij hebben we niet alleen
                            op kleur maar vooral ook op houdbaarheid geselecteerd.
                        </p>
                    </div>
                </div>
                <div className="row mb-5 align-items-center">
                    <div className="col-md-6 h-100 my-4 text-center" style={{ fontSize: "1.2rem", fontWeight: 500 }}>
                        <p className="h3 mt-0">Bekijk ons assortiment!</p>
                        <Link to="/assortiment" className="btn btn-lg btn-primary px-4">Assortiment</Link>
                    </div>
                    <div className="col">
                        <img src={ stalletje } alt="Stalletje" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(OverOns);
